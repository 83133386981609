import BackendService from "./BackendService"
import UserService from "./UserService";

export default class BdpoService {


    static listBdpo(baseDate, payload) {
        return BackendService.post("/bdpo/reference-date/" + baseDate + "/summary/filter", payload).then(resp => {
            console.log(resp)
            return resp
        })
    }

    static createReferenceDate(payload) {
        return BackendService.post("/bdpo/reference-date", payload).then(resp => {
            return resp
        })
    }

    static patchReferenceDate(referenceDateId, payload) {
        return BackendService.patch("/bdpo/reference-date/" + referenceDateId, payload).then(resp => {
            return resp
        })
    }

    static downloadSummary(id) {
        return BackendService.get("/bdpo/download/" + id).then(resp => {
            return resp
        })
    }

    static generateSummary(referenceDateId) {

        let payload = {
            "companyRole": UserService.company
        }

        return BackendService.post("/bdpo/reference-date/" + referenceDateId + "/summary", payload).then(resp => {
            return resp
        })
    }
}