<template>
    <div class="card flex col-12" style="width: 100%;">
        <div class="flex col-10" style="gap: 15px">
            <div class="p-float-label">
                <Calendar class="customCalendarBdpo" v-model="selectedOptions.baseDate" view="month"
                    dateFormat="mm/yy" />
                <label>{{ this.$langGetValue("referenceDate") }}</label>
            </div>
            <div class="p-float-label">
                <!-- optionLabel="name" -->
                <!-- <MultiSelect class="customMultiSelect" style="height:100%; min-width: 250px;"
                    v-model="selectedOptions.selectedStatus" :options="bdpoOptions.statusOptions" optionLabel="label"
                    filter>
                </MultiSelect> -->

                <MultiSelect class="customMultiSelect" style="height:100%; min-width: 250px;"
                    v-model="selectedOptions.selectedStatus" :options="bdpoOptions.statusOptions" optionLabel="label"
                    filter>
                    <template #value="slotProps">
                        <span v-if="slotProps.value.length === bdpoOptions.statusOptions.length">
                            {{ this.$langGetValue('all') }}
                        </span>
                        <span v-else>
                            {{ slotProps.value.map(item => item.label).join(',') }}
                        </span>
                    </template>
                </MultiSelect>

                <label>Status</label>
            </div>
            <div>
                <Button class="primaryButton" type="button" icon="pi pi-search" :loading="loading" @click="searchList"
                    style="height: 100% !important; width: 3.5rem;" />
            </div>
        </div>
        <div class="col-2 flex justify-content-end">
            <Button icon="pi pi-plus" :label="this.$langGetValue('summaryBdpo')" @click="generateSummaryDialog = true"
                class="primaryButton" :disabled="incidentList == null"
                v-tooltip.top="{ value: this.$langGetValue('generateSummary') }" style="height: 100%" />


        </div>
    </div>
    <div class="card" v-if="incidentList != null">
        <div v-if="incidentList.records.length > 0">
            <DataTable class="customDataTable" v-model:expandedRows="expandedRows" :value="incidentList.records" lazy
                style="margin-top: 24px" :rows="pages" :rowsPerPageOptions="[5, 10, 20, 50]" paginator
                :totalRecords="totalRecords" @page="paginate($event)" :first="this.currentPage * pages">
                <Column expander style="width: 3rem; padding-right: 0"> </Column>
                <Column style="padding-left: 0" field="header">
                    <template #body="slotProps">
                        <!-- {{ slotProps }} -->
                        <div class="flex" style="justify-content: space-between; align-items: center;">
                            <div class="flex ml-2" style="gap: 20px; align-items: center;">
                                <div style="display: flex; flex-direction: column; align-items: start; gap: 5px;">
                                    <Chip class="secondaryChip" style="height: fit-content; width: 130px;"
                                        :label="slotProps.data.name" />
                                </div>

                                <div>
                                    <div style="font-size: 12px; font-weight: 400;">
                                        {{ this.$langGetValue("createDate") }}
                                    </div>
                                    <div class="flex mt-1" style="align-items: center; gap: 5px; font-weight: 800;">
                                        <i class="pi pi-calendar"></i>
                                        {{ convertDate(slotProps.data.createdTimestamp) }}
                                    </div>
                                </div>

                                <div style="height: 35px; border: 1px solid #9f9e9e;"></div>

                                <div>
                                    <div style="font-size: 12px; font-weight: 400;">
                                        {{ this.$langGetValue("user") }}
                                    </div>
                                    <div class="flex mt-1" style="align-items: center; gap: 5px; font-weight: 800;">
                                        <i class="pi pi-user"></i>
                                        {{ emailToName(slotProps.data.createdBy) }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-content-between align-items-center" style="padding: 15px;">
                                <div class="flex mr-4" style="gap: 20px; align-items: center;"
                                    v-if="slotProps.data.status == 3">
                                    <div>
                                        <div style="font-size: 12px; font-weight: 400;">
                                            {{ this.$langGetValue("totalLoss") }}
                                        </div>
                                        <div class="flex mt-1" style="align-items: center; gap: 5px; font-weight: 800;">
                                            {{ "R$ " + slotProps.data.totalLossAmount }}
                                        </div>
                                    </div>

                                    <div>
                                        <div style="font-size: 12px; font-weight: 400;">
                                            {{ this.$langGetValue("events") }}
                                        </div>
                                        <div class="flex mt-1" style="align-items: center; gap: 5px; font-weight: 800;">
                                            {{ slotProps.data.eventCount }}
                                        </div>
                                    </div>
                                </div>

                                <div class="flex" style="gap: 10px; background: white;
                            padding: 10px;
                            border-radius: 20px; align-items: center;">
                                    <!-- <Skeleton v-tooltip.top="{ value: getStatus(slotProps.data.status) }" shape="circle"
                                        size="2rem" :style="{
                                            'background-color': getColor(slotProps.data.status),
                                        }">
                                    </Skeleton> -->

                                    <Chip style="height: 30px; width: 180px; justify-content: center ; background: var(--color_gray_xlight); color: var(--color_gray_xdark);border: 1px solid var(--color_gray_light); box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                                        font-weight: 700;" :label="getStatus(slotProps.data.status)" />

                                    <div v-if="slotProps.data.status == 1 || slotProps.data.status == 2" class="flex"
                                        style="gap: 5px">
                                        <button style="width: 2rem; height: 2rem;" disabled
                                            v-tooltip.top="{ value: this.$langGetValue('downloadXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-download"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('generateXML') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-cog"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('validate') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-check"></i>
                                        </button>
                                    </div>
                                    <div v-if="slotProps.data.status == 3" class="flex" style="gap: 5px">
                                        <button style="width: 2rem; height: 2rem;" disabled
                                            v-tooltip.top="{ value: this.$langGetValue('downloadXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-download"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('generateXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-cog"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('validate') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-check"></i>
                                        </button>
                                    </div>
                                    <div v-if="slotProps.data.status == 4" class="flex" style="gap: 5px">
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('downloadErrors') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-download"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('generateXML') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-cog"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('validate') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-check"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </template>
                </Column>

                <template #expansion="slotProps">
                    <!-- {{ slotProps }} -->

                    <!-- <div style="border: 1px solid grey;">

                    <div class="flex" style="flex-direction: row;">
                        <div style="width: 100px;" class="headCells">Basiléia</div>
                        <div style="width: 200px;" class="headCells" v-for="value in slotProps.data.details.baselLevel"
                            :key="value">{{ value.year }}</div>
                    </div>


                    <div class="flex">
                        <div class="rowCells" style="border-right: 1px solid black; border-bottom: 1px solid black;">
                        </div>
                        <div class="flex" v-for="value in slotProps.data.details.baselLevel" :key="value">
                            <div style="width: 100px; background-color: var(--color_brand_xlight) !important; border-bottom: 1px solid black;"
                                class="ordinaryCells">Eventos</div>
                            <div style="width: 100px; background-color: var(--color_brand_xlight) !important; border-bottom: 1px solid black;"
                                class="ordinaryCells">Valor</div>
                        </div>
                    </div>

                    <div class="flex">
                        <div class="rowCells" style="border-right: 1px solid black; border-bottom: 1px solid black;">
                            lvl 1</div>
                        <div v-for="value in slotProps.data.details.baselLevel" :key="value">
                            <div v-for="info in value.info" :key="info">
                                <div v-if="info.lvl == 1" class="flex">
                                    <div style="width: 100px; background-color: #93c1ff !important"
                                        class="ordinaryCells">{{ info.eventCount }}</div>
                                    <div style="width: 100px; background-color: #93c1ff !important"
                                        class="ordinaryCells">{{ 'R$ ' + info.totalLossAmount }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex">
                        <div class="rowCells" style="border-right: 1px solid black; border-bottom: 1px solid black;">
                            lvl 2</div>
                        <div v-for="value in slotProps.data.details.baselLevel" :key="value">
                            <div v-for="info in value.info" :key="info">
                                <div v-if="info.lvl == 2" class="flex">
                                    <div style="width: 100px; background-color: whitesmoke !important;"
                                        class="ordinaryCells">{{ info.eventCount }}</div>
                                    <div style="width: 100px; background-color: whitesmoke !important;"
                                        class="ordinaryCells">{{ 'R$ ' + info.totalLossAmount }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex">
                        <div class="rowCells" style="border-right: 1px solid black;">
                            lvl 3</div>
                        <div v-for="value in slotProps.data.details.baselLevel" :key="value">
                            <div v-for="info in value.info" :key="info">
                                <div v-if="info.lvl == 3" class="flex">
                                    <div style="width: 100px; background-color: #93c1ff !important"
                                        class="ordinaryCells">{{ info.eventCount }}</div>
                                    <div style="width: 100px; background-color: #93c1ff !important"
                                        class="ordinaryCells">{{ 'R$ ' + info.totalLossAmount }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> -->
                    <div style="overflow-x: auto; display: block; max-width: 100%;" v-if="slotProps.data.status == 3">
                        <DataTable class="summaryTable" :value="slotProps.data.details.table.data"
                            tableStyle="min-width: 50rem">
                            <ColumnGroup type="header">
                                <Row>
                                    <Column :header="this.$langGetValue('baselBdpo')" :rowspan="2" />
                                    <Column v-for="col in slotProps.data.details.table.columns" :key="col" :header="col"
                                        :colspan="slotProps.data.details.table.subColumns.length" />
                                </Row>
                                <Row>
                                    <Column v-for="subcol in this.getSubCols(slotProps.data)" :key="subcol.header"
                                        :header="subcol.header" :field="subcol.field" />
                                </Row>
                            </ColumnGroup>
                            <Column field="baselLevel" />
                            <Column v-for="subcol in this.getSubCols(slotProps.data)" :key="subcol.header"
                                :field="subcol.field" />
                        </DataTable>
                    </div>
                    <!-- {{ slotProps.data.details.table }} -->
                    <div class="flex flex-column">
                        <div v-if="1 == 2">
                            {{ slotProps }}
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>
        <div v-else
            style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px; padding-top: 16px;" />
            <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
                {{ this.$langGetValue("errorNoSummaries") }}
            </div>
        </div>
    </div>
    <div style="width: 100%;" v-if="attachments != null">
        <AttachmentComponent :attachments="this.attachments"
            :attachmentsMode="checkRole(['*.storm.bdpo.*.file-upload']) == true ? 'edit' : 'readonly'"
            @returned-ids="setAttachments">
        </AttachmentComponent>
    </div>

    <!-- <Dialog class="generateSummary" style="width: 450px" v-model:visible="generateSummaryDialog" modal header=" "
        :dismissable-mask="true">
        <div class="flex" style="width: 100%">
            <div class="flex align-items-center"
                style="border: 3px solid rgb(237 197 15); border-radius: 12px 0px 0px 12px; padding: 16px; background: antiquewhite;">
                <i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i>
            </div>
            <div class="flex"
                style="border: 3px solid rgb(237 197 15); border-radius: 0px 12px 12px 0px; padding: 16px; font-weight: 500; background: antiquewhite; border-left: none;">
                Ao clicar em "Gerar Resumo" será iniciado um novo fluxo de resumo
            </div>
        </div>
        <template #footer>
            <div style="width:100%" class="text-center">
                <Button label="Gerar Resumo" class="primaryButton"
                    @click="generateSummaryDialog = false; generateSummary()" />
            </div>
        </template>
    </Dialog> -->

    <Dialog class="statusIncidentDialog" :showHeader="false" v-model:visible="generateSummaryDialog" style="width: 30vw"
        :modal="true">
        <Vue3Lottie ref="lottieContainer2" :animationData="thunderAnimation" :height="100" :width="100" />
        <div class="mt-3">
            <h5 style="margin-left: 20px; font-weight: bold">
                {{ this.$langGetValue('summaryBdpo') }}
            </h5>
            <!-- <p style="margin-left: 20px">
                {{ this.$langGetValue("dialog1") }} {{ statusIncident }}
            </p> -->
            <p style="margin-left: 20px">
                {{ this.$langGetValue('clickToGenerate') }}
            </p>
        </div>
        <template #footer>
            <Button :label="this.$langGetValue('generate')" @click="generateSummaryDialog = false; generateSummary()"
                style="width: 90px; background: var(--color_brand_medium);" />
            <Button :label="this.$langGetValue('buttonClose')" @click="generateSummaryDialog = false;"
                style="width: 90px; background: var(--color_brand_medium);" />
        </template>
    </Dialog>


    <Dialog class="generateXMLDialog" style="width: 450px" v-model:visible="generateXMLDialog" modal
        :dismissable-mask="true">
        <div style="width: 100%; padding: 10px;">
            <div>
                <div style="font-size: 14px; font-weight: 600;">
                    {{ this.$langGetValue('minimumValue') }}
                </div>
                <InputNumber class="mt-2" v-model="minimumAmount" inputId="integeronly" style="width: 100%;" />
            </div>
            <div style="margin-top: 25px;">
                <div style="font-size: 14px; font-weight: 600;">
                    {{ this.$langGetValue("company") }}
                </div>
                <MultiSelect style="height: fit-content; width:100%" v-model="companySelected" optionLabel="label"
                    :disabled="false" :options="filteredCompany" optionGroupLabel="label" optionGroupChildren="children"
                    :placeholder="this.$langGetValue('selectOption')" :maxSelectedLabels="3" :filter="true"
                    @filter="filterCompany">
                    <template #optiongroup="slotProps">
                        <div class="flex items-center">
                            <div>{{ slotProps.option.label }}</div>
                        </div>
                    </template>
                    <template #value>
                        <div class="flex" style="flex-wrap: nowrap;">
                            <span v-for="(value, index) in companySelected.slice(0, 3)" :key="index">
                                {{ value.label }}<span v-if="index < Math.min(2, companySelected.length - 1)">, </span>
                            </span>
                            <span v-if="companySelected.length > 3">...</span>
                        </div>
                    </template>
                </MultiSelect>
            </div>
        </div>
        <template #footer>
            <div style="width:100%" class="text-center">
                <Button :label="this.$langGetValue('generateXML')" class="primaryButton" style="width:100px" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import "../Global.css";
import BdpoService from "../service/BdpoService";
import Util from "../service/Util";
import AttachmentComponent from "../components/AttachmentComponent.vue"
import FieldService from "../service/FieldService";
import UserService from "../service/UserService";
import { Vue3Lottie } from "vue3-lottie";
import thunderAnimation from "../assets/animations/AnimacaoThunder.json";

export default {
    components: {
        AttachmentComponent,
        Vue3Lottie
    },
    data() {
        return {
            thunderAnimation,
            expandedRows: [],

            bdpoOptions: {
                companyOptions: FieldService.getField('incident', 'company'),
                statusOptions: FieldService.getField('bdpo', 'bdpoSummaryStatus')
            },

            selectedOptions: {
                baseDate: null,
                selectedStatus: FieldService.getField('bdpo', 'bdpoSummaryStatus'),
                minimumAmount: null
            },

            filteredCompany: null,
            generateSummaryDialog: false,
            generateXMLDialog: false,
            incidentList: null,
            attachments: null,
            loading: false,

            pages: parseInt(Util.pageSize),
            currentPage: 0,
            totalRecords: 0,
        };
    },
    created() {

    },
    beforeUnmount() {
        clearInterval(this.autoRefreshInterval);
    },
    async mounted() {



        this.filteredCompany = this.bdpoOptions.companyOptions

        this.autoRefreshInterval = setInterval(() => {
            this.getList();
        }, 90000);
    },
    methods: {
        setAttachments(att) {
            this.attachments = att

            let payload = {
                "attachment": this.attachments
            }

            BdpoService.patchReferenceDate(this.incidentList.extraFields.id, payload).then((response) => {
                if (response.status != 200) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Erro ao salvar anexo!",
                        life: 5000,
                    });
                    return
                }
            })
        },
        searchList() {
            if (this.selectedOptions.selectedStatus.length == 0 || this.selectedOptions.baseDate == null) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue("blankFields"),
                    life: 5000,
                });
                return
            }
            this.getList()
        },
        paginate(event) {
            if (event.rows != this.pages) {
                Util.setRemoteStorage("pagesize", event.rows).then(() => {
                    this.pages = event.rows;
                    Util.pageSize = event.rows
                    this.getList()
                });
            }
            else {
                this.currentPage = event.page;
                this.getList()
            }
        },
        async getList(newResume = false) {
            if (this.selectedOptions.selectedStatus.length == 0 || this.selectedOptions.baseDate == null) {
                return
            }

            let payload = {
                "orderBy": [
                    {
                        "field": "id",
                        "direction": "DESC"
                    }
                ],
                "sqlWhereClause": {
                    "condition": "OR",
                    "rules": [
                    ]
                },
                "page": this.currentPage + 1,
                "pageSize": this.pages
            }

            if (newResume) {
                this.selectedOptions.selectedStatus = this.bdpoOptions.statusOptions
            }

            this.selectedOptions.selectedStatus.forEach((status) => {
                payload.sqlWhereClause.rules.push({
                    "field": "status",
                    "type": "integer",
                    "operator": "equal",
                    "value": status.id
                })
            })

            let baseDate = this.selectedOptions.baseDate;

            let nextMonth = new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 1);

            let lastDayOfMonth = new Date(nextMonth - 1).getDate();

            let formattedDate = `${this.selectedOptions.baseDate.getFullYear()}-${String(this.selectedOptions.baseDate.getMonth() + 1).padStart(2, '0')}-${String(lastDayOfMonth)}`;

            this.loading = true
            BdpoService.listBdpo(formattedDate, payload).then((response) => {
                this.loading = false
                if (response.status == 200) {
                    this.totalRecords = response.data.totalRecords
                    this.refreshData(response.data)
                    return
                }
                if (response.status == 404) {
                    this.createReferenceDate(formattedDate)
                    return
                }

                this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue("toast8"),
                    life: 5000,
                });
                return
            })
        },
        createReferenceDate(referenceDate) {
            let payload = {
                "companyRole": UserService.company,
                "referenceDate": referenceDate
            }

            BdpoService.createReferenceDate(payload).then((response) => {
                this.refreshData(response.data)
            })
        },
        refreshData(data) {
            // this.incidentList = null

            // Ainda tá piscando. ajustar

            this.incidentList = data

            console.log(this.attachments, data.extraFields.attachment)
            if (this.attachments != data.extraFields.attachment) {
                this.attachments = null

                this.$nextTick(() => {
                    this.attachments = data.extraFields.attachment
                })
            }

            // this.incidentList = data
            

            // this.$nextTick(() => {

            // })
        },
        getColor(status) {
            if (status == 1) {
                return '#ffbc00' //amarelo
            }
            if (status == 2) {
                return 'green'
            }
            if (status == 3) {
                return 'red'
            }
        },
        convertDate(date) {
            return Util.formatDate(date, true)
        },
        emailToName(mail) {
            return Util.emailToName(mail)
        },
        generateSummary() {

            if (this.selectedOptions.baseDate == null) {
                this.$toast.add({
                    severity: "error",
                    summary: "Campos em branco!",
                    life: 5000,
                });
                return
            }

            Util.loading = true
            BdpoService.generateSummary(this.incidentList.extraFields.id).then((response) => {
                Util.loading = false
                if (response.status == 200) {
                    this.getList(true)
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Erro ao criar resumo!",
                        life: 5000,
                    });
                    return
                }
            })
        },
        downloadSummary(name, id) {
            BdpoService.downloadSummary(id).then((resp) => {
                const fileBlob = new Blob([resp], { type: 'application/XML' });

                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(fileBlob);

                var fileName = name + ".XML";
                a.download = fileName;

                a.click();
            }).catch((error) => {
                console.error("Erro ao baixar o arquivo:", error);
            });
        },
        filterCompany(event) {
            if (event.value.trim().length === 0) {
                this.filteredCompany = this.company;
                return;
            }

            let tempCompany = [];

            this.biaOptions.company.forEach((group) => {
                let matchedChildren = group.children.filter((child) =>
                    child.label.toLowerCase().includes(event.value.toLowerCase())
                );

                if (matchedChildren.length > 0) {
                    let tempGroup = { ...group };
                    tempGroup.children = matchedChildren;
                    tempCompany.push(tempGroup);
                }
            });

            this.filteredCompany = [...tempCompany];
        },
        getSubCols(data) {
            let subcols = [];
            data.details.table.columns.forEach((col) => {
                data.details.table.subColumns.forEach((subCol) => {
                    subcols.push({ field: col + "." + subCol, header: subCol });
                });
            });
            return subcols;
        },
        getStatus(status) {
            return this.bdpoOptions.statusOptions.filter(a => a.id == status)[0].label
        },
        checkRole(roles) {
            return UserService.checkUserRoleAny(roles)
        },
    },
};
</script>
<style>
.customInfoDatatable {
    display: block;
}

.headCells {
    background-color: var(--color_brand_medium);
    color: white;
    justify-content: center;
    display: flex;
}

.rowCells {
    width: 100px;
    display: flex;
    justify-content: center;
    background-color: var(--color_brand_xlight);
    color: black;
}

.ordinaryCells {
    display: flex;
    justify-content: center;
    border-right: 1px solid black;
    border-top: none;
    border-bottom: none;
    border-left: none;
}

/* .customDataTable th {
    display: none;
} */



.customDataTable .p-datatable-tbody tr td {
    padding-top: 0px;
    padding-bottom: 0px;
}

.customDataTable .p-datatable-loading-overlay {
    display: none;
}

.customDataTable .p-datatable-row-expansion {
    background: #f7f7f7 !important;
}

.customCalendarBdpo {
    height: 50px;
    border-radius: 4px;
}

.customDropdown {
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
}

.generateXMLDialog .p-dialog-header {
    justify-content: end;
}

.statusIncidentDialog .p-dialog-content {
    border-radius: 12px 12px 0px 0px;
}

.statusIncidentDialog .p-dialog-footer {
    border-radius: 0px 0px 12px 12px;
}

/* .customDataTable .summaryTable .p-column-header-content {
    justify-content: center;
}

.customDataTable .summaryTable th {
    border: 0.5px solid black !important;
    background-color: var(--color_brand_medium) !important;
    color: white !important;
    font-weight: 500 !important;
}

.customDataTable .summaryTable td {
    padding: 5px !important;
    text-align: center !important;
    border: 0.5px solid grey !important;
} */
</style>